import { useContext } from "react";
import { Context } from "../../../context";
import Image from "next/image";
import LayoutFillImg from "../LayoutFillImg/LayoutFillImg";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { BiMenuAltRight, BiUserPlus } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";
import { UseHeaderContext } from "../../../context/HeaderContext";
import { UseWindowContext } from "../../../context/WindowContext";
// import logo from "../../../public/logo.png";
// import flora_logo from "../../../public/flora_logo.png";
import outdoorbuilderslogo from "../../../public/outdoorbuilderslogo-removebg-preview.png";
import Container from "../container/Container";
import DashboardProfile from "../dashboardProfile/DashboardProfile";
import SideNavigation from "../../Dashboard/SideNavigation/SideNavigation";
import Button from "../button/Button";
import styles from "./header.module.scss";
import { useUserProfile } from "../../../hooks/auth";
import ChatHeader from "@/components/Chat/chatHeader/ChatHeader";
import { setCookie, getCookie, hasCookie, deleteCookie } from 'cookies-next';

export default function Header() {
  const {
    setMenuTopHeight,
    setHeaderHeight,
    hContainerMWidth,
    specialNavPath,
  } = UseHeaderContext();
  const [isScroll, setIsScroll] = useState(false);
  const router = useRouter();
  const headerRef = useRef(null);
  const menuTopRef = useRef(null);
  const overlayRef = useRef(null);
  const menusWrapperRef = useRef(null);
  const { windowWidth } = UseWindowContext();

  const { data: user, isLoading, error } = useUserProfile();

  // condition
  const windowCondition = windowWidth < 992;

  // get header height
  const getHeaderHeight = useCallback(() => {
    setHeaderHeight(headerRef.current.getBoundingClientRect().height);
    windowCondition &&
      setMenuTopHeight(menuTopRef.current.getBoundingClientRect().height);
  }, [setHeaderHeight, setMenuTopHeight, windowCondition]);
  // set header height
  useEffect(() => {
    getHeaderHeight();
  }, [getHeaderHeight]);

  // resize window
  const onResizeWindow = useCallback(() => {
    getHeaderHeight();
    if (router.pathname !== "/user/chat") {
      windowCondition ? menuToggler() : menuToggler("OPEN", "noOverlay");
    } else {
      return;
    }
  }, [getHeaderHeight, windowCondition, router.pathname]);
  // invoked
  useEffect(() => {
    onResizeWindow();
    // invoked window width --resize
    window.addEventListener("resize", onResizeWindow);
    return () => window.removeEventListener("resize", onResizeWindow);
  }, [onResizeWindow]);

  // active menu tab
  function menuActiveTab(menu) {
    if (router.asPath === menu) {
      return styles.active;
    } else if (router.asPath === "/" && menu.toLowerCase() === "home") {
      return styles.active;
    } else if (
      router.asPath ===
      "/" + menu.toLowerCase().split(" ").join("-")
    ) {
      return styles.active;
    } else if (router.asPath == menu.split("/").includes("business")) {
      return styles.active;
    }
  }

  useEffect(() => {
    // on scroll
    function onScroll() {
      window.scrollY > 80 ? setIsScroll(true) : setIsScroll(false);
    }
    // invoked on scroll
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // on menu click
  const menuToggler = (type, overType) => {
    const overlay = overlayRef.current;
    const menu = menusWrapperRef.current;

    if (type === "OPEN") {
      if (overType !== "noOverlay") {
        overlay.style.opacity = 1;
        overlay.style.pointerEvents = "all";
      }
      menu.style.transform = `translateX(0)`;
    } else {
      menu.style.transform = `translateX(100%)`;
      overlay.style.opacity = 0;
      overlay.style.pointerEvents = "none";
    }
  };

  const routeChangeHandler = useCallback(() => {
    windowCondition && menuToggler();
  }, [windowCondition]);

  // router change
  useEffect(() => {
    router.events.on("routeChangeComplete", routeChangeHandler);
    return () => router.events.off("routeChangeComplete", routeChangeHandler);
  }, [routeChangeHandler, router.events]);


   const [isOpen, setIsOpen] = useState(false);
   const [selectedBusiness, setSelectedBusiness] = useState(hasCookie('business') ? JSON.parse(getCookie('business')) : null);
   const [businessOptions, setBusinessOptions] = useState([]);
  
    const toggleDropdown = () => {
      setIsOpen((prev) => !prev);
      setProjectIsOpen(false);
    };

  const handleBusinessSelect = (option) => {

    setSelectedBusiness(option);
    setIsOpen(false); 

    deleteCookie('project');

    if (!option.value) {
      deleteCookie('business');
      //deleteCookie('project');
      return location.reload();
    }

    setCookie('business', option);

    if (option.slug)
      location.href = option.slug;

  };

  useEffect(() => {

    if (user && !businessOptions.length) {
      
     let options = [{
      value: '',
      label: 'All Business'
      }];
      
      if (!getCookie('business')) {
        setSelectedBusiness({
          value: '',
          label: 'All Business'
        })
      }


    options = options.concat(user?.all_business.map(business => ({
      value: business._id,
      label: business.name,
      slug: (business.createdBy == user._id || business.admins.includes(user._id)) ? `/business/project-management` : `/business/employee-project-management`,
    })));

      
      setBusinessOptions(options)
     
    }

   }, [user]);
  
  
   const [isProjectOpen, setProjectIsOpen] = useState(false);
   const [selectedProject, setSelectedProject] = useState(hasCookie('project') ? JSON.parse(getCookie('project')) : null);
   const [projectOptions, setProjectOptions] = useState([]);

   const toggleProjectDropdown = () => {
     setProjectIsOpen((prev) => !prev);
     setIsOpen(false);
    };

  const handleProjectSelect = (option) => {
    //console.log('project option',option)

    setProjectIsOpen(false); 
    setSelectedProject(option);

    if (!option.value) {
      deleteCookie('project');
      return location.reload();
    }
    
    setCookie('project', option);

    if (option.slug)
      location.href = option.slug;

  };

  useEffect(() => {

    if (user && selectedBusiness) {
      
      let options = [{
      value: '',
      label: 'All Projects'
      }];
      
      if (!getCookie('project')) {
        setSelectedProject({
          value: '',
          label: 'All Projects'
        })
      }

     // if (!selectedBusiness.value) {
        /*
        options = options.concat(user.all_projects.map(project => ({
          value: project._id,
          label: project.title,
          slug: (project.admins.includes(user._id)) ? `/business/project-management` : `/business/employee-project-management`,
          //slug: `/user/projects/${project.slug}`
        })));*/
     // }
      if (selectedBusiness.value) {
        options = options.concat(
        user.all_projects
          .filter(project => project.businessId === selectedBusiness.value)
          .map(project => ({
            value: project._id,
            label: project.title,
            slug: (/*business.createdBy == user._id || */project.admins.includes(user._id)) ? `/business/project-management` : `/business/employee-project-management`,
            //slug: `/user/projects/${project.slug}`
          }))
      );
      }

      if (options.length == 1) {
        options[0].label = 'No Projects';
        setSelectedProject({
          value: '',
          label: 'No Projects'
        })
      }
      
      setProjectOptions(options)
     
    }

    }, [user, selectedBusiness]);


  return (
    <>
      {router.pathname === specialNavPath && specialNavPath.includes("chat") ? (

        <ChatHeader />
      ) : (
        <header
          className={`${styles.header}${isScroll ? " " + styles.scroll : ""}`}
          ref={headerRef}
        >
          <Container
            style={
              router.pathname === specialNavPath
                ? { maxWidth: hContainerMWidth }
                : {}
            }
          >
     
            <nav className={styles.nav}>
              <div className={styles.logo}>
                <Link href="/">
                  <Image
                    src={outdoorbuilderslogo}
                    alt="Outdoor builders AI logo"
                    width={100}
                    height={38}
                  />
                </Link>
              </div>
              {windowCondition && (
                <button
                  type="button"
                  className={styles.menuBar}
                  onClick={() => menuToggler("OPEN")}
                >
                  <BiMenuAltRight />
                </button>
              )}
              <div
                className={styles.overlay}
                ref={overlayRef}
                onClick={menuToggler}
              />
              <div className={styles.menusWrapper} ref={menusWrapperRef}>
                {windowCondition && (
                  <div className={styles.menuTopBar} ref={menuTopRef}>
                    <div className={styles.logo}>
                      <Link href="/">
                        <Image
                          src={outdoorbuilderslogo}
                          alt="Outdoor builders AI logo"
                          width={100}
                          height={38}
                        />
                      </Link>
                    </div>
                    <button className={styles.menuClose} onClick={menuToggler}>
                      <MdOutlineClose />
                    </button>
                  </div>
                )}
                {router.pathname === specialNavPath &&
                specialNavPath == "/dashboard" &&
                windowCondition ? (
                  <SideNavigation isTitle={false} className="between" />
                ) : (
                  <ul className={styles.menus}>
                    <>
                      {!user &&
                        !router.pathname.split("/").includes("business") ===
                          false && (
                          <li>
                            <Link href="/free-trial">
                              <span className={menuActiveTab("/free-trail")}>
                                Free Trial
                              </span>
                            </Link>
                          </li>
                        )}

                      {user && (
                            <>
   
   
                              


<li>
                                
          
<div className={styles.dropdown}>
      <button className={styles.dropdownToggle} onClick={toggleDropdown}>
                                  {selectedBusiness ? selectedBusiness.label : 'Select Business'}
                                   <span className={styles.arrow}>&#9660;</span> 
      </button>
      {isOpen && (
        <ul className={styles.dropdownMenu}>
          {businessOptions.map((option) => (
            <li
              key={option.value}
              className={styles.dropdownItem}
              onClick={() => handleBusinessSelect(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>

                              </li>
                              

                              {projectOptions.length>0 && (
                                <li>
                                
          
                                  <div className={styles.dropdown}>
                                    <button className={styles.dropdownToggle} onClick={toggleProjectDropdown}>
                                      {(selectedProject && selectedBusiness?.value) ? selectedProject.label : 'Select Project'}
                                      <span className={styles.arrow}>&#9660;</span>
                                    </button>
                                    {isProjectOpen &&  (
                                      <ul className={styles.dropdownMenu}>
                                        {projectOptions.map((option) => (
                                          <li
                                            key={option.value}
                                            className={styles.dropdownItem}
                                            onClick={() => handleProjectSelect(option)}
                                          >
                                            {option.label}
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </div>

                                </li>
                              )}
                              
                          <li>
                            <Link href="/" className={menuActiveTab("home")}>
                              <span>Home</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              href="/user/projects"
                              className={menuActiveTab("user/projects")}
                            >
                              <span>Projects</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              href="/user/chat"
                              className={menuActiveTab("user/chat")}
                            >
                              <span>Messages</span>
                            </Link>
                          </li>

                          <Link href="/credits">
                            <span>
                              <strong>
                                {user.remainingImagegenerationCredits} credits
                              </strong>
                            </span>
                          </Link>
                        </>
                      )}
                      {(router.pathname || router.asPath) == specialNavPath ||
                      user ? (
                        <li className={styles.dashboardProfileLink}>
                          <DashboardProfile />
                        </li>
                      ) : (
                        <ul className={styles.buttonsDiv}>
                          <li className={styles.menusBtnLink}>
                            <Link href="/business">
                              <div className={menuActiveTab("/auth/business")}>
                                <Button
                                  variant={"secondary"}
                                  // onClick={() => router.push("/auth/business")}
                                >
                                  Business
                                </Button>
                              </div>
                            </Link>
                          </li>
                          <li className={styles.menusBtnLink}>
                            <Link href="/auth">
                              <div className={menuActiveTab("/auth")}>
                                <Button
                                  variant={"cta"}
                                  // onClick={() => router.push("/auth")}
                                >
                                  Login
                                </Button>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </>
                  </ul>
                )}
              </div>
            </nav>{" "}
          </Container>
        </header>
      )}
    </>
  );
}
